<template>
    <!-- eslint-disable max-len -->
    <div class="down-indicator">
        <svg width="56px" height="56px" viewBox="0 0 56 56" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient x1="50.0028638%" y1="-256.276428%" x2="50.0028638%" y2="469.768768%" :id="`${gradientId}_DownIndicator`">
                    <stop
                        v-for="colorStop in colorStops"
                        :key="colorStop.position"
                        :stop-color="colorStop.color"
                        :offset="colorStop.position"
                    ></stop>
                </linearGradient>
            </defs>
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="Final-Mobile" transform="translate(-132.000000, -650.000000)">
                    <g id="Down-button" transform="translate(132.000000, 650.000000)">
                        <circle id="Oval" :fill="`url(#${gradientId}_DownIndicator)`" cx="28" cy="28" r="28"></circle>
                        <g id="angle-arrow-down" transform="translate(16.000000, 23.000000)" :fill="iconColor" fill-rule="nonzero">
                            <path d="M23.7601929,1.44280154 L22.5578723,0.240565193 C22.397664,0.0800199348 22.2131127,0 22.0044713,0 C21.7963352,0 21.6118682,0.0800199348 21.4516599,0.240565193 L12.0001263,9.69159334 L2.54901396,0.240817888 C2.3887214,0.0802726293 2.20425439,0.000252694531 1.99586564,0.000252694531 C1.78739265,0.000252694531 1.60292564,0.0802726293 1.44271731,0.240817888 L0.240565193,1.44313847 C0.0800199348,1.6033468 0,1.78781381 0,1.99628679 C0,2.20459132 0.0802726293,2.38905833 0.240565193,2.54926666 L11.446978,13.7559322 C11.6071864,13.9162247 11.7917376,13.9963289 12.0001263,13.9963289 C12.2085151,13.9963289 12.3927294,13.9162247 12.5528535,13.7559322 L23.7601929,2.54926666 C23.9204012,2.3889741 24,2.20450709 24,1.99628679 C24,1.78781381 23.9204012,1.6033468 23.7601929,1.44280154 Z" id="Path"></path>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'DownIndicator',
    data() {
        return {
            gradientId: null,
        };
    },
    props: {
        colorStops: {
            type: Array,
            default: () => [
                { color: '#6590FF', position: '0%' },
                { color: '#4DC9E2', position: '50%' },
                { color: '#35FFC6', position: '100%' },
            ],
        },
        darkMark: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        iconColor() {
            let iconColor;
            if (this.darkMark === true) {
                iconColor = '#438BF8';
            } else {
                iconColor = '#FFF';
            }
            return iconColor;
        },
    },
    mounted() {
        this.gradientId = this._uid;
    },
};
</script>

<style lang="scss" scoped>
    .down-indicator {
        width: 56px;
        height: 56px;
    }
</style>
