<template>
    <header class="header" :class="{ 'header--error': blockingError.enabled }">
        <div class="header__content">
            <a href="https://feedbackcompany.com" title="Feedback Company Homepage" target="_blank">
                <FeedbackCompanyLogo :color="logoStyle.color" :width="logoStyle.width" />
            </a>
        </div>
    </header>
</template>

<script>
import { FeedbackCompanyLogo } from '@feedbackcompany/feedback-company-vue-components';
import { vueWindowSizeMixin } from 'vue-window-size';

export default {
    name: 'Header',
    mixins: [vueWindowSizeMixin],
    components: {
        FeedbackCompanyLogo,
    },
    computed: {
        logoStyle() {
            const logoStyle = {
                color: 'blue',
                width: 85,
            };
            if (this.windowWidth > 767 || this.blockingError.occurred) {
                logoStyle.color = 'white';
                logoStyle.width = 127;
            }
            return logoStyle;
        },
        blockingError() {
            return this.$store.state.blockingError;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.header {
    width: 100%;
    @media (max-width: 769px) {
        background-color: $grey_bg;
        height: 92px;
    }
    @media (min-width: 768px) {
        background-image: linear-gradient(-100deg, $blue 10%, darken($blue, 4%) 40%);
        height: 192px;
    }
    &.header--error {
        background-image: linear-gradient(-100deg, $red 0%, darken($red, 0%) 40%);
    }
}
.header__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 769px) {
        height: 68px;
    }
    @media (min-width: 768px) {
        height: 120px;
    }
}
</style>
