<template>
    <span v-if="label && !mergeComponents" v-html="label" :style="style"></span>
    <StringTemplateRenderer v-else-if="label && mergeComponents" :content="label" :style="style">
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData" />
        </template>
    </StringTemplateRenderer>
    <span v-else-if="showSlot" :style="style"><slot></slot></span>
    <span v-else class="text_loader" :style="style">
        <span class="loader__glow" :style="style"></span>
        <span ref="substitute">{{ sub }}</span>
    </span>
</template>

<script>
import Vue from 'vue';
import BaseComponent from '@feedbackcompany/feedback-company-vue-components/src/components/BaseComponent';
import StringTemplateRenderer from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/StringTemplateRenderer.vue';

/**
 * TextLoader, this is the text pre loader. When you don't have a label or content yet,
 * you can use this to show a grey loading bar instead.
 * You can either use a slot or the text property for it's content.
 */
export default {
    name: 'TextLoader',
    mixins: [BaseComponent],
    components: {
        StringTemplateRenderer,
    },
    props: {
        // A Label Key, put the key of a label here and this element will fetch it.
        text: {
            type: String,
        },
        /*
The substitute for the label, this is also used to determine the width of the loader
If the label can't be fetched, this will be shown.
*/
        sub: {
            required: true,
            type: String,
        },
        /*
If you use the slot option of this component, this is the string that determines if
the slot or the loading animation should be shown, if this string is longer than 0 characters
It'll show the slot.
*/
        awaiting: {
            default: '',
            type: String,
        },
        /*
Sometimes values have to be inserted in labels, you can use merge-tags for that.
You can insert a merge tag in to a label encapsulated like [[count]].
Then add "count" in the mergeTags prop and it will replace this.
*/
        mergeTags: {
            type: Object,
            default: () => ({}),
        },
        /**
         * For performance reasons it's best to not check every label string for the component
         * merge tags "[[component]]. Hence why we have a mergeComponents prop,
         * which is there for performance reasons.
         */
        mergeComponents: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            useSubstitute: false,
            substituteHeight: null,
        };
    },
    computed: {
        label() {
            let label = Vue.prototype.$l(this.text, this.sub, this.mergeTags);
            if (label === '') label = null;
            if (this.useSubstitute) label = this.sub;
            return label;
        },
        style() {
            const height = this.substituteHeight;
            return {
                height: `${height}px`,
            };
        },
        slotContent() {
            // If the user decides to use a slot, that's fine, we'll handle that here.
            return this.$slots.default[0];
        },
        showSlot() {
            let showSlot = false;
            if (this.awaiting.length > 0) showSlot = true;
            return showSlot;
        },
    },
    mounted() {
        if (this.$refs.substitute) {
            this.substituteHeight = this.$refs.substitute.offsetHeight;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../../style_variables/style_variables.scss";

.text_loader {
    display: inline-flex;
    background: $grey_alabaster;
    color: transparent;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
    border: 0px;
    padding: 0px;
    margin: 0px;
}

.loader__glow {
    position: absolute;
    width: 100%;
    background: linear-gradient(
        90deg,
        rgba($grey_alabaster, 0) 0%,
        $grey_bg 40%,
        $grey_bg 60%,
        rgba($grey_alabaster, 0) 100%
    );
    animation: 1.2s loading-placeholder ease-in-out infinite;
}

@keyframes loading-placeholder {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}
</style>
