import Vue from 'vue';
import Vuex from 'vuex';
import vuexI18n from 'vuex-i18n';
import VueCompositionAPI from '@vue/composition-api';
import Vue2TouchEvents from 'vue2-touch-events';
import { setup } from '@feedbackcompany/feedback-company-vue-components';
import LabelQueuer from '@/i18n/LabelQueuer';
import FBCLabels from '@/i18n/FBCLabels';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(Vue2TouchEvents);

const config = {
    language: 'en',
    labelEndpoint: `${process.env.VUE_APP_API_BASE_URL}/form/labels/${window.location.pathname.split('/')[1]}`,
};

Vue.labelQueuer = new LabelQueuer({
    labelEndpoint: config.labelEndpoint,
    language: config.language,
    useCustomEndpoint: config.useCustomEndpoint,
});

setup();

Vue.use(vuexI18n.plugin, new Vuex.Store(), {
    moduleName: 'i18n',
    identifiers: ['{{', '}}'],
    onTranslationNotFound(locale, key) {
        Vue.labelQueuer.queueKey(key);
    },
});
Vue.i18n.set(Vue.labelQueuer.language);
Vue.i18n.fallback('label_fallback');
Vue.use(FBCLabels);
Vue.labelQueuer.getLabelsFromLocalStorage();

new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');

// API's that need loading before user can use app
if (router.currentRoute.params.shop) {
    store.dispatch('getShop');
    store.dispatch('getPages');
} else {
    store.commit('setLoadingState', {
        stateKey: 'formPages',
        status: false,
    });
    store.commit('setLoadingState', {
        stateKey: 'shopSettings',
        status: false,
    });
    store.commit('setError', {
        code: '404',
        message: 'No shop defined in URL',
    });
}
