<template>
    <div>
        <TextInput
            class="input"
            :required="required"
            :requiredMessage="requiredMessage"
            :numeric="true"
            type="number"
            :label="label"
            :min="1"
            :minMessage="(min) => $l('text_input__validation__value_under_min', '', { min })"
            :max="155"
            :maxMessage="(max) => $l('text_input__validation__value_over_max', '', { max })"
            v-model="inputValue"
        />
        <InputHelpText :helpText="helpText" />
    </div>
</template>

<script>
import TextInput from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue';
import InputHelpText from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/InputHelpText.vue';
import FormMixin from '@feedbackcompany/feedback-company-vue-components/src/components/mixins/FormMixin';

export default {
    name: 'AgeInput',
    mixins: [FormMixin],
    components: {
        TextInput,
        InputHelpText,
    },
    props: {
        value: {
            type: String,
        },
        helpText: {
            type: String,
        },
        required: {
            type: Boolean,
            default: false,
        },
        requiredMessage: {
            type: String,
        },
        label: {
            type: String,
        },
    },
    computed: {
        inputValue: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value ? value.toString() : '');
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.input {
    margin-bottom: 12px;
}
</style>
