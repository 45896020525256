var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[(_vm.page && _vm.page.nextPageLabel)?_c('main',[_c('form',{staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.nextFormStep.apply(null, arguments)}}},[_c('ShopInfo'),_c('hr',{staticClass:"divider"}),_vm._l((_vm.page.questions),function(question,key){return [(question.type === 'GenderInput')?_c(question.type,_vm._b({key:(key + "_" + (_vm.page.id)),tag:"GenderInput",attrs:{"value":question.answer,"optionalLabel":_vm.$l('general_input__indicators__optional', 'Optional'),"valueMaxLengthMessage":function (max_length) { return _vm.$l('text_input__validation__max_characters', '', { max_length: max_length }); }},on:{"input":function($event){return _vm.formfieldInput($event, {
                            key: key,
                            pageId: _vm.page.id,
                        })}}},'GenderInput',question.props,false)):_c(question.type,_vm._b({key:(key + "_" + (_vm.page.id)),tag:"component",attrs:{"value":question.answer,"optionalLabel":_vm.$l('general_input__indicators__optional', 'Optional')},on:{"input":function($event){return _vm.formfieldInput($event, {
                            key: key,
                            pageId: _vm.page.id,
                        })}}},'component',question.props,false))]}),(_vm.showAnonymousCheckbox)?_c('Checkbox',{attrs:{"label":_vm.$l('review_form__show_name', 'Show name with Review')},model:{value:(_vm.showName),callback:function ($$v) {_vm.showName=$$v},expression:"showName"}}):_vm._e(),_c('div',{staticClass:"form__submit_holder",class:{
                    'form__submit_holder--hidden':
                        _vm.isSubmitting && _vm.nextFormStepUrl.includes('success'),
                }},[_c('Button',{staticClass:"submit submit--normal",nativeOn:{"click":function($event){return _vm.submit(!_vm.showName)}}},[_vm._v(_vm._s(_vm.page.nextPageLabel))])],1)],2)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }