/* eslint "no-param-reassign": "off" */

/*
    This is a wrapper for VueX-i18n, it extends the $t function.
    It stores the requested labels and their substitutes.
    This enables returning an empty string till the label has been gotten.
*/
import TextLoader from '@/components/atoms/TextLoader.vue';

export default {
    install(Vue) {
        Vue.component('t', TextLoader);
        Vue.FBCLabels = {
            labels: {},
            onLabelsError({ keys }) {
                const labelObject = {};
                const localStorageLabels = JSON.parse(
                    localStorage.getItem(`FBCLabels_${Vue.labelQueuer.language}`),
                ) || [];
                keys.forEach((key) => {
                    const label = localStorageLabels[key] || Vue.FBCLabels.labels[key] || key;
                    labelObject[key] = label;
                });
                Vue.i18n.fallback('label_fallback');
                Vue.i18n.add('label_fallback', labelObject);
            },
        };

        Vue.prototype.$l = (key, sub, mergeTags) => {
            let returnLabel = null;
            if (key) returnLabel = Vue.i18n.translate(key, '', mergeTags);
            Vue.FBCLabels.labels[key] = sub;
            return returnLabel;
        };

        Vue.mixin({
            beforeMount() {
                if (this.componentLabels === undefined) return;
                this.componentLabels.forEach((componentLabel) => {
                    Vue.prototype.$l(componentLabel.key, componentLabel.sub);
                });
            },
        });
    },
};
