<template>
    <span>
        <main v-if="page && page.nextPageLabel">
            <form class="form" @submit.prevent="nextFormStep">
                <ShopInfo />
                <hr class="divider" />
                <template v-for="(question, key) in page.questions">
                    <GenderInput
                        v-if="question.type === 'GenderInput'"
                        :key="`${key}_${page.id}`"
                        :value="question.answer"
                        v-bind:is="question.type"
                        v-bind="question.props"
                        :optionalLabel="$l('general_input__indicators__optional', 'Optional')"
                        :valueMaxLengthMessage="
                            (max_length) =>
                                $l('text_input__validation__max_characters', '', { max_length })
                        "
                        @input="
                            formfieldInput($event, {
                                key,
                                pageId: page.id,
                            })
                        "
                    />
                    <component
                        v-else
                        :key="`${key}_${page.id}`"
                        :value="question.answer"
                        v-bind:is="question.type"
                        v-bind="question.props"
                        :optionalLabel="$l('general_input__indicators__optional', 'Optional')"
                        @input="
                            formfieldInput($event, {
                                key,
                                pageId: page.id,
                            })
                        "
                    />
                </template>
                <Checkbox
                    v-if="showAnonymousCheckbox"
                    v-model="showName"
                    :label="$l('review_form__show_name', 'Show name with Review')"
                />
                <div
                    class="form__submit_holder"
                    :class="{
                        'form__submit_holder--hidden':
                            isSubmitting && nextFormStepUrl.includes('success'),
                    }"
                >
                    <Button @click.native="submit(!showName)" class="submit submit--normal">{{
                        page.nextPageLabel
                    }}</Button>
                </div>
            </form>
        </main>
    </span>
</template>

<script>
import Button from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Button.vue';
import Checkbox from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Checkbox.vue';
import TextInput from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/TextInput.vue';
import SwitchInput from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/SwitchInput.vue';
import RadioQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/RadioQuestion.vue';
import TextInputQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/TextInputQuestion.vue';
import NpsQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/NpsQuestion.vue';
import AmountOfUnitPicker from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/AmountOfUnitPicker.vue';
import DropdownQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/DropdownQuestion.vue';
import SegmentedControlQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/SegmentedControlQuestion.vue';
import StarsQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/organisms/StarsQuestion.vue';
import CesQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/organisms/CesQuestion.vue';
import GenderInput from '@feedbackcompany/feedback-company-vue-components/src/components/organisms/GenderInput.vue';
import MultipleChoiceListQuestion from '@feedbackcompany/feedback-company-vue-components/src/components/organisms/MultipleChoiceListQuestion.vue';
import FormMixin from '@feedbackcompany/feedback-company-vue-components/src/components/mixins/FormMixin';
import AgeInput from '@/components/molecules/AgeInput.vue';
import router from '@/router';
import Header from '@/components/molecules/Header.vue';
import ShopInfo from '@/components/molecules/ShopInfo.vue';
import ChoiceBlock from '@/components/molecules/ChoiceBlock.vue';

export default {
    name: 'ReviewForm',
    mixins: [FormMixin],
    components: {
        Header,
        ShopInfo,
        TextInput,
        SwitchInput,
        Checkbox,
        AmountOfUnitPicker,
        DropdownQuestion,
        RadioQuestion,
        SegmentedControlQuestion,
        NpsQuestion,
        CesQuestion,
        StarsQuestion,
        Button,
        TextInputQuestion,
        AgeInput,
        GenderInput,
        ChoiceBlock,
        MultipleChoiceListQuestion,
    },
    data() {
        return {
            showName: true,
        };
    },
    computed: {
        showAnonymousCheckbox() {
            return (
                this.nextFormStepUrl.includes('success')
                && this.$store.state.shop.allow_anonymous_submit === true
            );
        },
        isSubmitting() {
            return this.$store.state.loadStates.submit;
        },
        page() {
            let page = this.$store.state.pages[0];
            if (this.$route.params.questionCategory) {
                page = this.$store.getters.getPageById(this.$route.params.questionCategory);
            }
            return page;
        },
        nextFormStepUrl() {
            const currentIndex = this.page.index;
            const nextFormStep = this.$store.state.pages[currentIndex + 1];
            let nextFormStepUrl = `/${this.$route.params.shop}/success`;
            if (nextFormStep !== undefined) {
                nextFormStepUrl = `/${this.$route.params.shop}/${nextFormStep.id}`;
            }
            return nextFormStepUrl;
        },
    },
    watch: {
        showName(showName) {
            const nameIndex = this.page.questions.findIndex(
                question => question.id === 'fullname',
            );
            this.$store.commit('setRequired', {
                pageIndex: this.page.index,
                questionIndex: nameIndex,
                required: showName,
            });
        },
    },
    methods: {
        submit(anonymously) {
            this.$store.commit('submitAnonymous', anonymously);
            window.requestAnimationFrame(() => {
                this.nextFormStep();
            });
        },
        formfieldInput(answer, questionIndexData) {
            this.$store.commit('setAnswer', {
                answer,
                pageIndex: this.page.index,
                questionIndex: questionIndexData.key,
            });
        },
        nextFormStep() {
            this.showWhichInputsAreInvalid();
            if (!this.isValid) return false;
            if (this.nextFormStepUrl.includes('success')) {
                this.$store.dispatch('postReview');
            } else {
                router.push(this.nextFormStepUrl);
            }
        },
    },
    beforeMount() {
        this.$on('validChange', (input) => {
            if (input.$vnode.key === undefined) return;
            this.$store.commit('setValid', {
                pageIndex: this.page.index,
                questionIndex: input.$vnode.key.split('_')[0],
                isValid: input.isValid,
            });
        });
    },
};
</script>

<style lang="scss" scoped>
@import "src/style_variables/style_variables.scss";

main {
    width: 100%;
    min-height: calc(100vh - 68px);
    max-width: 792px;
    background-color: $white;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    box-shadow: 0 10px 20px 0 rgba(30, 30, 30, 0.05);
    animation-name: loadZoomIn;
    animation-duration: 0.4s;

    @media (max-width: 769px) {
        margin: -24px auto 0px auto;
    }
    @media (min-width: 768px) {
        margin: -72px auto 0px auto;
    }
}

@keyframes loadZoomIn {
    from {
        opacity: 0;
        transform: scale(0.98);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.form {
    width: 100%;
    padding: 24px 8px 0px;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 24px;
    grid-auto-rows: min-content;
    @media (max-width: 769px) {
        padding: 24px 8px;
    }
    @media (min-width: 768px) {
        padding: 24px 48px;
    }
}

.form__submit_holder {
    width: 100%;
    max-width: $input_max_width;
    display: inline-flex;
    justify-content: space-between;
    transition: all 200ms ease-out;

    .submit {
        width: 100%;
        height: 48px;
    }

    .submit--anonymously {
        margin-right: 1em;
    }
}

.form__submit_holder--hidden {
    pointer-events: none;
    opacity: 0;
}

.divider {
    border: 0;
    border-top: 1px solid $grey_athens;
    @media (max-width: 769px) {
        width: calc(100% + 16px);
        margin: 0 0 0 -8px;
    }
    @media (min-width: 768px) {
        width: calc(100% + 96px);
        margin: 0 0 0 -48px;
    }
}
</style>
