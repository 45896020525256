<template>
    <header class="shop_info">
        <figure class="shop_info__logo">
            <img
                :src="shop.logo"
                :alt="shop.name + ' logo'"
            />
        </figure>
        <main class="shop_info__text">
            <p class="shop_info__intro">
                <t text="review_form_title_overline" sub="Your review for"/>
            </p>
            <h1 class="shop_info__name">{{shop.name}}</h1>
        </main>
    </header>
</template>

<script>
export default {
    name: 'ShopInfo',
    computed: {
        shop() {
            return this.$store.state.shop;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/style_variables/style_variables.scss";

    .shop_info {
        display: flex;
    }
    .shop_info__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .shop_info__intro {
        @extend %body1_style;
        margin: 0px;
    }
    .shop_info__name {
        @extend %headline5_style;
        margin: 0px;
        @media (min-width: 768px) {
            font-size: 34px;
        }
    }
    .shop_info__logo {
        width: 72px;
        height: 72px;
        border-radius: 6px;
        border: 1px solid $grey_alabaster;
        box-shadow: 0 10px 20px 0 rgba(30,30,30,0.05);
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px 16px 0px 0px;
        overflow: hidden;

        @media (min-width: 768px) {
            width: 96px;
            height: 96px;
        }

        img {
            width: 100%;
        }
    }
</style>
