import Vue from 'vue';
import Vuex from 'vuex';
import queryString from 'query-string';
import errorStore from '@feedbackcompany/feedback-company-vue-components/src/stores/errorStore';
import NotificationsStore from '@feedbackcompany/feedback-company-vue-components/src/stores/notificationStore';
import router from '@/router';
import { version } from '../package.json';

window.feedbackcompmany_reviewform_version = version;
Vue.use(Vuex);

let setTitleCalled = 0;
let tempStoreName;
const setTitle = (storeName) => {
    setTitleCalled += 1;
    if (storeName) tempStoreName = storeName;
    if (setTitleCalled < 2) return;
    document.title = Vue.i18n.translate('review_form_page_title', {
        shop_name: tempStoreName,
    });
};

const blockingError = {
    ...errorStore,
    namespaced: true,
    state: () => ({
        ...errorStore.state,
    }),
};
const friendlyError = {
    ...errorStore,
    namespaced: true,
    state: () => ({
        ...errorStore.state,
    }),
};

const store = new Vuex.Store({
    modules: {
        blockingError,
        friendlyError,
        notifications: NotificationsStore,
    },
    state: {
        isSubmitted: false,
        loadStates: {
            submit: false,
            shopSettings: true,
            formPages: true,
            labels: true,
        },
        pages: [],
        shop: {
            logo: '',
            name: '',
            confetti_enabled: true,
            confetti_colors: [''],
            success_state_emoji: '',
            allow_anonymous_submit: '',
        },
        urlParameters: {
            ...queryString.parse(window.location.search),
            ...{
                form_version: version,
            },
        },
        sourceURL: window.location.href,
        submitResponseData: {},
        submitAnonymous: false,
    },
    mutations: {
        setShop(state, shop) {
            Vue.set(state, 'shop', shop);
            setTitle(shop.name);
        },
        setAnswer(state, { pageIndex, questionIndex, answer }) {
            state.pages[pageIndex].questions[questionIndex].answer = answer;
        },
        setRequired(state, { pageIndex, questionIndex, required }) {
            if (
                state.pages[pageIndex]
                && state.pages[pageIndex].questions[questionIndex]
            ) {
                state.pages[pageIndex].questions[questionIndex].props.required = required;
            }
        },
        setValid(state, { pageIndex, questionIndex, isValid }) {
            if (
                state.pages[pageIndex]
                && state.pages[pageIndex].questions[questionIndex]
            ) {
                state.pages[pageIndex].questions[questionIndex].isValid = isValid;
            }
        },
        setError(state, { code, message, friendly }) {
            const errorObjectName = friendly ? 'friendlyError' : 'blockingError';
            state[errorObjectName].occurred = true;
            state[errorObjectName].code = code;
            state[errorObjectName].message = message;
            if (friendly) router.push({ name: 'Success' });
        },
        setPages(state, pages) {
            state.pages = pages;
        },
        setLoadingState(state, { stateKey, status }) {
            state.loadStates[stateKey] = status;
        },
        setSubmitted(state, data) {
            state.isSubmitted = true;
            state.submitResponseData = data;
        },
        submitAnonymous(state, submitAnonymous) {
            state.submitAnonymous = submitAnonymous;
        },
    },
    actions: {
        getShop({ state, commit }) {
            const urlParamsStringified = queryString.stringify(state.urlParameters);
            commit('setLoadingState', {
                stateKey: 'shopSettings',
                status: true,
            });
            fetch(`${process.env.VUE_APP_API_BASE_URL}/form/shop_info/${router.currentRoute.params.shop}?${urlParamsStringified}`)
                .then(async (response) => {
                    const responseData = await response.json();
                    if (responseData.error) {
                        commit('setError', {
                            code: response.status,
                            message: responseData.error,
                            friendly: responseData.friendlyError,
                        });
                        commit('setLoadingState', {
                            stateKey: 'shopSettings',
                            status: false,
                        });
                        return;
                    }
                    responseData.slug = router.currentRoute.params.shop;
                    commit('setShop', responseData);
                    commit('setLoadingState', {
                        stateKey: 'shopSettings',
                        status: false,
                    });
                })
                .catch((response) => {
                    commit('setError', {
                        code: '500',
                        message: response,
                    });
                    commit('setLoadingState', {
                        stateKey: 'shopSettings',
                        status: false,
                    });
                });
        },
        getPages({ state, commit }) {
            const urlParamsStringified = queryString.stringify(state.urlParameters);
            commit('setLoadingState', {
                stateKey: 'formPages',
                status: true,
            });
            fetch(`${process.env.VUE_APP_API_BASE_URL}/form/config/${router.currentRoute.params.shop}?${urlParamsStringified}`)
                .then(async (response) => {
                    const responseData = await response.json();
                    if (responseData.error) {
                        commit('setError', {
                            code: response.status,
                            message: responseData.error,
                            friendly: responseData.friendlyError,
                        });
                        commit('setLoadingState', {
                            stateKey: 'formPages',
                            status: false,
                        });
                        return;
                    }
                    const indexedResponse = responseData;
                    indexedResponse.forEach((page, n) => { page.index = n; });
                    responseData.forEach((page) => {
                        page.questions.forEach((question) => {
                            question.isValid = false;
                        });
                    });
                    commit('setPages', indexedResponse);
                    commit('setLoadingState', {
                        stateKey: 'formPages',
                        status: false,
                    });
                })
                .catch((response) => {
                    commit('setError', {
                        code: 500,
                        message: response,
                    });
                    commit('setLoadingState', {
                        stateKey: 'formPages',
                        status: false,
                    });
                });
        },
        postReview({ state, commit, getters }) {
            if (state.isSubmitted) {
                commit('pushNotification', {
                    type: 'warning',
                    message: Vue.i18n.translate('review_form_validation_already_submitted', {
                        shop_name: state.shop.name,
                    }),
                });
                return;
            }
            if (getters.answeredQuestionsPercentage !== 100) {
                commit('pushNotification', {
                    type: 'warning',
                    message: Vue.i18n.translate('review_form_validation_missing_answers'),
                    actionName: 'Go to start',
                    action: () => {
                        router.push(`/${state.shop.url_slug}`);
                    },
                });
                return;
            }
            const submitError = () => {
                commit('pushNotification', {
                    type: 'error',
                    message: Vue.i18n.translate('review_form__errors_critical_submit'),
                });
                commit('setLoadingState', {
                    stateKey: 'submit',
                    status: false,
                });
            };
            commit('setLoadingState', {
                stateKey: 'submit',
                status: true,
            });
            fetch(`${process.env.VUE_APP_API_BASE_URL}/form/save_review/${router.currentRoute.params.shop}?form_version=${version}`, {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    form_return_data: getters.questionsFormattedForSubmit,
                    urlParameters: state.urlParameters,
                    sourceURL: state.sourceURL,
                }),
                headers: {
                    'Content-Type': 'text/plain',
                },
            })
                .then(async (response) => {
                    const responseData = await response.json();
                    if (responseData.error) {
                        commit('pushNotification', {
                            type: 'error',
                            message: responseData.error,
                        });
                        commit('setLoadingState', {
                            stateKey: 'submit',
                            status: false,
                        });
                        return;
                    }
                    router.push(`/${router.currentRoute.params.shop}/success`);
                    commit('setSubmitted', responseData);
                    commit('setLoadingState', {
                        stateKey: 'submit',
                        status: false,
                    });
                })
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error('Feedback Company Error:', error);
                    submitError();
                });
        },
    },
    getters: {
        getPageById: state => id => state.pages.find(page => page.id === id) || {},
        getAllQuestions: (state) => {
            let allQuestions = [];
            state.pages.forEach((page) => {
                allQuestions = [...allQuestions, ...page.questions];
            });
            return allQuestions;
        },
        answeredQuestionsPercentage: (state, getters) => {
            const requiredQuestions = getters.getAllQuestions.filter(
                question => question.props.validationType || question.props.required,
            );
            const filteredQuestions = requiredQuestions.filter(
                question => question.isValid === true,
            );
            const percentage = (filteredQuestions.length / requiredQuestions.length) * 100;
            return percentage;
        },
        questionsFormattedForSubmit: (state, getters) => {
            const questionsFormattedForSubmit = getters.getAllQuestions.map((question) => {
                const returnQuestion = {};
                let { answer } = question;
                if (answer === undefined) answer = null;
                if (question.id === 'fullname' && state.submitAnonymous) answer = 'anonymous';
                returnQuestion[question.id] = answer;
                return returnQuestion;
            });
            return questionsFormattedForSubmit;
        },
    },
});

export default store;
