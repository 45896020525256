<template>
    <div class="choice_block">
        <Formfield
            :required="props.required"
            :help-text="props.helpText"
            :label="props.label"
            :optionalLabel="$l('general_input__indicators__optional', 'Optional')"
        >
            <component
                v-bind:is="inputType"
                v-model="componentValue"
                :forceOptionMatch="true"
                :minLengthToOpenTray="0"
                :searchKeys="['value', 'displayValue']"
                :textInputProps="textInputProps"
                :required="props.required"
                :searchAble="true"
                v-bind="props"
            />
        </Formfield>
    </div>
</template>

<script>
import InputBase from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/mixins/InputBase';
import FormMixin from '@feedbackcompany/feedback-company-vue-components/src/components/mixins/FormMixin';
import Formfield from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/Formfield.vue';
import Dropdown from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/Dropdown.vue';
import TextInputAutocomplete from '@feedbackcompany/feedback-company-vue-components/src/components/molecules/TextInputAutocomplete.vue';
import RadioList from '@feedbackcompany/feedback-company-vue-components/src/components/atoms/Inputs/RadioList.vue';

export default {
    name: 'ChoiceBlock',
    mixins: [InputBase, FormMixin],
    components: {
        Formfield,
        RadioList,
        Dropdown,
        TextInputAutocomplete,
    },
    data() {
        if (this.$attrs.value) this.$emit('input', this.$attrs.value, this);
        const optionSelected = this.$attrs.options.find(option => option.value === this.value);
        let setValue = '';
        if (optionSelected) {
            if (this.$attrs.options.length > 9) {
                setValue = optionSelected.displayValue;
            } else {
                setValue = optionSelected.value;
            }
        }
        return {
            componentValue: setValue,
            props: this.$vnode.data.attrs,
            textInputProps: {
                label: this.$vnode.data.attrs.placeholder,
                value: '',
            },
        };
    },
    computed: {
        inputType() {
            let inputType = 'RadioList';
            if (this.props.options.length > 4) inputType = 'Dropdown';
            return inputType;
        },
        optionSelected() {
            return this.props.options.find(option => option.value === this.componentValue);
        },
        valueFromOptions() {
            let valueFromOptions = '';
            if (this.optionSelected) valueFromOptions = this.optionSelected.value;
            return valueFromOptions;
        },
    },
    watch: {
        valueFromOptions() {
            this.inputValue = this.valueFromOptions;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
</style>
