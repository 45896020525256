<template>
    <div class="progress_container">
        <div
            class="gooey_loader"
            :class="{
                'gooey_loader--showing': isSubmitting,
            }"
            v-bind:style="progressBarStyle">
            <div class="gooey_loader__bulbs_holder" v-bind:style="progressBarStyle">
                <div class="gooey_loader__bulb gooey_loader__bulb--first"></div>
                <div class="gooey_loader__bulb gooey_loader__bulb--middle"></div>
                <div class="gooey_loader__bulb gooey_loader__bulb--last"></div>
            </div>
            <div class="gooey_loader__base" v-bind:style="progressBarStyle"></div>
        </div>
        <figure class="progress__bar" v-bind:style="progressBarStyle">
            <div class="progress__fill" v-bind:style="progressFillStyle"></div>
        </figure>
    </div>
</template>

<script>
export default {
    name: 'ProgressBar',
    props: {
        percentage: {
            required: true,
            type: Number,
            default: 0,
        },
        height: {
            default: 6,
        },
    },
    computed: {
        isSubmitting() {
            return this.$store.state.loadStates.submit;
        },
        progressBarStyle() {
            return {
                height: this.height,
            };
        },
        progressFillStyle() {
            return {
                width: `${this.percentage}%`,
            };
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";
.goo-effect {
    position: fixed;
    left: -1000px;
}
.progress_container {
    position: relative;
}
.gooey_loader {
    position: absolute;
    bottom: 0px;
    width: 100%;
    height: 400px;
    pointer-events: none;
    left: 50%;
    transform: translate(-50%, 0px);
    transition: opacity 200ms;
    opacity: 0;
}
.gooey_loader--showing {
    opacity: 1;

    .gooey_loader__bulb {
        animation-duration: 800ms;
        animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
        animation-name: bobble;
        animation-iteration-count: infinite;
    }
}
.gooey_loader__bulbs_holder {
    position: absolute;
    width: 100px;
    left: 50%;
    transform: translateX(-50%);
}
.gooey_loader__bulb {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    background-color: $blue;
    transition: all 0.8s;
}
.gooey_loader__bulb--first {
    left: 16px;
    animation-delay: 100ms;
}
.gooey_loader__bulb--middle {
    left: calc(50% - 8px);
    animation-delay: 200ms;
}
.gooey_loader__bulb--last {
    right: 16px;
    animation-delay: 300ms;
}
.gooey_loader__base {
    width: 100%;
    background-color: $blue;
}
@keyframes bobble {
    0% {
        transform: translate(0px, 0px);
    }
    50% {
        transform: translate(0px, -45px);
    }
    100% {
        transform: translate(0px, -0px);
    }
}
.progress__bar {
    margin: 0px;
    background-color: transparent;
}
.progress__fill {
    height: 100%;
    transition: all 200ms;
    background-image: linear-gradient(-37deg, #6EDCB1 0%, #287CFB 100%);
}
</style>
