import Vue from 'vue';
import Router from 'vue-router';
import Form from './views/Form.vue';
import Success from './views/Success.vue';
import Verify from './views/Verify.vue';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/:shop',
            name: 'Main',
            component: Form,
        },
        {
            path: '/:shop/success',
            name: 'Success',
            component: Success,
        },
        {
            path: '/:shop/verify',
            name: 'Verify',
            component: Verify,
        },
        {
            path: '/:shop/:questionCategory',
            name: 'Review Form',
            component: Form,
        },
    ],
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});

export default router;
