<template>
    <EndScreen :hasLargeMessage="extra_user_messages" :confettiEnabled="verified && shoot_confetti">
        <div class="email-verifcation">
            <SuccessScreenContent
                v-if="verified"
                :extraMessages="[
                    $l(
                        'review_form__succes_page__email_verification__thanks',
                        'Hartelijk dank voor het verifiëren van uw e-mailadres!'
                    ),
                ]"
            ></SuccessScreenContent>
            <div v-else class="email_verifcation__retry">
                <p class="email_verifcation__retry__text" v-if="!verifying">
                    {{
                        $l(
                            "review_form__errors_critical_submit",
                            "Something went wrong, please try again later"
                        )
                    }}
                </p>
                <div class="email_verifcation__retry__button_centerer">
                    <LoaderCircular v-if="verifying" />
                    <Button v-else @click.native="verify">
                        {{ $l("review_form__verify_page__click_to_verify_email", "Retry") }}
                    </Button>
                </div>
            </div>
        </div>
    </EndScreen>
</template>

<script>
import queryString from 'query-string';
import { LoaderCircular, Button } from '@feedbackcompany/feedback-company-vue-components';
import EndScreen from '@/components/pages/EndScreen.vue';
import SuccessScreenContent from '@/components/organisms/SuccessScreenContent.vue';

export default {
    name: 'Verify',
    components: {
        EndScreen,
        SuccessScreenContent,
        LoaderCircular,
        Button,
    },
    data() {
        return {
            verifying: false,
            verified: false,
        };
    },
    computed: {
        shop() {
            return this.$store.state.shop;
        },
        extra_user_messages() {
            return this.$store.state.submitResponseData.extra_user_messages;
        },
        email_verification_needed() {
            return this.$store.state.submitResponseData.email_verification_needed;
        },
        // This is to tell the EndScreen component if it should shoot confetti.
        shoot_confetti() {
            return this.$store.state.shop.confetti_enabled && !this.email_verification_needed;
        },
    },
    methods: {
        verify() {
            this.verifying = true;
            const urlParamsStringified = queryString.stringify({
                ...this.$store.state.urlParameters,
                ...{
                    language: this.$store.state.shop.language,
                },
            });
            fetch(
                `${process.env.VUE_APP_API_BASE_URL}/api/v2/review/emailvalidation?${urlParamsStringified}`,
            )
                .then(async (response) => {
                    // eslint-disable-next-line no-throw-literal
                    if (response.status < 200 || response.status > 299) throw 'Server Error';
                    const responseData = await response.json();
                    // eslint-disable-next-line no-throw-literal
                    if (responseData.error) throw 'Response Error';
                    this.verifying = false;
                    this.verified = true;
                })
                .catch(() => {
                    this.verified = false;
                    this.verifying = false;
                });
        },
    },
    mounted() {
        this.verify();
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.email_verifcation__retry {
    padding-top: 32px;
    padding-bottom: 20px;
}
.email_verifcation__retry__text {
    @extend %body1_style;
    text-align: center;
}
.email_verifcation__retry__button_centerer {
    display: flex;
    justify-content: center;
}
</style>
