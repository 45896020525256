<template>
    <div id="app">
        <TabOnlyFocus />
        <Header v-if="isForm" />
        <div
            v-if="loadStates.formPages === true || loadStates.shopSettings === true"
            class="loader"
        >
            <LoaderCircular />
        </div>
        <main v-else-if="!error.occurred">
            <router-view class="router-view" />
        </main>
        <BlockingError v-else :code="error.code" :message="error.message" />
        <ProgressBar
            v-if="page && page.nextPageLabel && showProgressBar"
            class="form__progress_bar"
            :percentage="questionsAnsweredPercentage"
            height="10px"
        />
        <NotificationRail :notification-list="notificationList" />
    </div>
</template>

<script>
import {
    TabOnlyFocus,
    BlockingError,
    NotificationRail,
    LoaderCircular,
} from '@feedbackcompany/feedback-company-vue-components';
import ProgressBar from '@/components/atoms/ProgressBar.vue';
import Header from '@/components/molecules/Header.vue';

export default {
    name: 'App',
    components: {
        Header,
        ProgressBar,
        TabOnlyFocus,
        BlockingError,
        NotificationRail,
        LoaderCircular,
    },
    computed: {
        page() {
            let page = this.$store.state.pages[0];
            if (this.$route.params.questionCategory) {
                page = this.$store.getters.getPageById(this.$route.params.questionCategory);
            }
            return page;
        },
        loadStates() {
            return this.$store.state.loadStates;
        },
        error() {
            return this.$store.state.blockingError;
        },
        questionsAnsweredPercentage() {
            return this.$store.getters.answeredQuestionsPercentage;
        },
        blockingError() {
            return this.$store.state.blockingError;
        },
        showProgressBar() {
            return this.$route.name !== 'Success';
        },
        notificationList() {
            return this.$store.state.notifications.all;
        },
        isForm() {
            return this.$route.matched[0].components.default.name === 'ReviewForm';
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.form__progress_bar {
    width: 100%;
    z-index: 2;
    left: 0px;
    position: fixed;
    bottom: 0px;
}
</style>

<style lang="scss">
@import "@feedbackcompany/feedback-company-vue-components/src/style_variables/_typography_classes";

html,
body {
    margin: 0px;
    padding: 0px;
}
* {
    box-sizing: border-box;
}
.loader {
    margin: 0px auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}
</style>
