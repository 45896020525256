import Vue from 'vue';

export default class LabelQueuer {
    constructor({ labelEndpoint, language, useCustomEndpoint }) {
        this.labelEndpoint = labelEndpoint;
        this.useCustomEndpoint = useCustomEndpoint;
        this.language = language;
        this.keyQueue = [];
        this.keyHistory = [];
        this.localStorageLabels = JSON.parse(localStorage.getItem(`FBCLabels_${this.language}`));
    }

    clearQueue() {
        this.keyQueue = [];
    }

    setLanguage({ language }) {
        this.language = language;
        this.localStorageLabels = JSON.parse(localStorage.getItem(`FBCLabels_${this.language}`));
        this.keyQueue = this.keyHistory;
        this.getLabelsFromLocalStorage();
        this.getLabelsFromAPI();
    }

    queueKey(key) {
    // There's no check here for Local Storage because we want to be able
    // to update the labels without cache clearing and the call is light.
        if (this.keyHistory.includes(key)) return;
        this.keyQueue.push(key);
        this.keyHistory.push(key);
        this.startGetCountDown();
    }

    startGetCountDown() {
        clearTimeout(this.getCountDown);
        this.getCountDown = window.setTimeout(() => {
            this.getLabels();
        }, 50);
    }

    getLabels() {
        this.getLabelsFromAPI();
    }

    getLabelsFromLocalStorage() {
        Vue.i18n.fallback(`local_storage_${this.language}`);
        Vue.i18n.add(`local_storage_${this.language}`, this.localStorageLabels);
    }

    getLabelsFromAPI() {
        const queue = this.keyQueue;
        this.clearQueue();
        const labelsToGet = queue.join(',');
        let labelEndpoint = `${this.labelEndpoint}?language=${this.language}&keys=${labelsToGet}`;
        // eslint-disable-next-line prefer-destructuring
        if (this.useCustomEndpoint) labelEndpoint = this.labelEndpoint;
        fetch(labelEndpoint)
            .then(async (response) => {
                const responseJSON = await response.json();
                if (responseJSON.error) {
                    Vue.FBCLabels.onLabelsError({ keys: queue });
                    return;
                }
                const responseData = await responseJSON.data;
                // Check which keys weren't fetched
                const difference = queue.filter(x => !Object.keys(responseData).includes(x));
                Vue.FBCLabels.onLabelsError({ keys: difference });

                Vue.i18n.add(this.language, responseData);
                Vue.i18n.set(this.language);
                // Always store the labels, because we assume the API is right.
                const labelsToStore = {
                    ...JSON.parse(localStorage.getItem(`FBCLabels_${this.language}`)),
                    ...responseData,
                };
                localStorage.setItem(`FBCLabels_${this.language}`, JSON.stringify(labelsToStore));
            })
            .catch(() => {
                Vue.FBCLabels.onLabelsError({ keys: queue });
            });
    }
}
