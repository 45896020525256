<template>
    <EndScreen
        :hasLargeMessage="extra_user_messages !== undefined"
        :confettiEnabled="shoot_confetti"
    >
        <SuccessScreenContent/>
    </EndScreen>
</template>

<script>
import EndScreen from '@/components/pages/EndScreen.vue';
import SuccessScreenContent from '@/components/organisms/SuccessScreenContent.vue';

export default {
    name: 'Success',
    components: {
        EndScreen,
        SuccessScreenContent,
    },
    computed: {
        shop() {
            return this.$store.state.shop;
        },
        extra_user_messages() {
            return this.$store.state.submitResponseData.extra_user_messages;
        },
        email_verification_needed() {
            return this.$store.state.submitResponseData.email_verification_needed;
        },
        shoot_confetti() {
            return (
                this.$store.state.shop.confetti_enabled
                && !this.email_verification_needed
                && !this.$store.state.friendlyError.occurred
            );
        },
    },
    mounted() {
        if (this.shop.reset_form_after_submit === false) return;
        setTimeout(() => {
            window.location = `/${this.shop.slug}/`;
        }, 6000);
    },
};
</script>

<style lang="scss" scoped>
    @import "@/style_variables/style_variables.scss";
</style>
