<template>
  <span class="succes_card__content">
    <div class="success_card__body">
      <h4 v-if="!friendlyErrorOccurred" class="success_card__title">
        {{ succes_card_title }}
      </h4>
      <p v-if="!email_verification_needed && !put_on_hold" class="success_card__message">
        <t
          v-if="!friendlyErrorOccurred"
          text="review_form__success_page__intro"
          sub="Thanks for your review!"
        />
        <span v-else>{{ friendlyErrorMessage }}</span>
      </p>
      <p
        v-for="message in extra_user_messages"
        v-bind:key="message"
        v-html="message"
        class="success_card__message"
        :class="{
          'success_card__message--extra-message': !email_verification_needed,
        }"
      ></p>
    </div>
  </span>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCopy } from '@fortawesome/pro-light-svg-icons';
import { mapState } from 'vuex';

library.add(faCopy);

export default {
    name: 'SuccessScreenContent',
    props: {
        extraMessages: {
            type: Array,
            default() {
                return [];
            },
        },
    },
    data() {
        return {
            justCopied: false,
            ComponentLabels: [
                {
                    key: 'review_form__success_page__title',
                    sub: 'Success',
                },
                {
                    key: 'review_form__success_page__title_review_received',
                    sub: 'Review Received',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            friendlyErrorOccurred: state => state.friendlyError.occurred,
            friendlyErrorMessage: state => state.friendlyError.message,
        }),
        shop() {
            return this.$store.state.shop;
        },
        extra_user_messages() {
            return [
                ...this.$store.state.submitResponseData.extra_user_messages || [],
                ...this.extraMessages,
            ];
        },
        email_verification_needed() {
            return this.$store.state.submitResponseData.email_verification_needed;
        },
        put_on_hold() {
            return this.$store.state.submitResponseData.put_on_hold;
        },
        succes_card_title() {
            let succesCardTitle = this.$t('review_form__success_page__title', 'Success');
            if (this.extra_user_messages.length) {
                succesCardTitle = this.$t('review_form__success_page__title_review_received', 'Review Received');
            }
            return succesCardTitle;
        },
    },
};
</script>

<style lang="scss" scoped>
    @import "@/style_variables/style_variables.scss";

.success_card__body {
  padding: 0 60px;
  text-align: inherit;
  @media only screen and (max-width: 600px) {
    padding: 0 12px;
  }
}
.success_card__title {
  @extend %headline4_style;
  text-align: inherit;
  margin: 24px 0 0 0;
}
.success_card__message {
  @extend %body1_style;
  color: $grey_mineshaft;
  text-align: inherit;
  margin: 16px 0 0 0;
}
.success_card__message--extra-message {
  @extend %body2_style;
}
.copy_icon {
  margin-right: 6px;
}
</style>
