<template>
    <span class="end_screen">
        <div class="success">
            <div class="cards">
                <div
                    class="cards__card cards__card--success"
                    :class="{
                        'cards__card--large-message': hasLargeMessage,
                    }"
                >
                    <div
                        class="success_card__emoji"
                        @click="shootConfetti"
                        :class="{
                            'success_card__emoji--just-shot-confetti': justShotConfetti,
                        }"
                    >{{shop.success_state_emoji}}</div>
                    <div class="succes_card__overflow-container">
                        <slot></slot>
                    </div>
                </div>
                <DownIndicator
                    @click.native="introScroll"
                    class="first_down_indicator"
                    :dark-mark="true"
                    :color-stops="[
                        { color: '#FFF', position: '0%' },
                        { color: '#FFF', position: '50%' },
                        { color: '#FFF', position: '100%' },
                    ]"
                />
            </div>
            <canvas id="confettiCanvas" ref="confettiCanvas"></canvas>
        </div>
        <EwfPromo ref="EwfPromo"/>
    </span>
</template>

<script>
import confetti from 'canvas-confetti';
import { setTimeout } from 'timers';
import DownIndicator from '@/components/atoms/DownIndicator.vue';

export default {
    name: 'EndScreen',
    components: {
        EwfPromo: () => import(/* webpackChunkName: "EwfPromo" */ '@/components/organisms/EwfPromo.vue'),
        DownIndicator,
    },
    props: {
        hasLargeMessage: {
            type: Boolean,
            default: false,
        },
        confettiEnabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            confetti: confetti.create(this.$refs.confettiCanvas, { resize: true }),
            justShotConfetti: false,
            justCopied: false,
        };
    },
    computed: {
        shop() {
            return this.$store.state.shop;
        },
    },
    mounted() {
        setTimeout(() => {
            this.shootConfetti();
        }, 25);
    },
    methods: {
        shootConfetti() {
            if (!this.confettiEnabled) return;
            const confettiSettings = {
                particleCount: 125,
                spread: 80,
                startVelocity: 33,
                decay: 0.94,
                ticks: 850,
                colors: this.$store.state.shop.confetti_colors,
            };
            this.confetti(confettiSettings);
            this.justShotConfetti = true;
            setTimeout(() => {
                this.justShotConfetti = false;
            }, 200);
        },
        introScroll() {
            this.$refs.EwfPromo.introScroll();
        },
    },
    watch: {
        confettiEnabled() {
            if (this.confettiEnabled === true) {
                this.shootConfetti();
            }
        },
    },
};
</script>

<style lang="scss">
    canvas {
        z-index: 1 !important;
    }
</style>
<style lang="scss" scoped>
    @import "@/style_variables/style_variables.scss";

    .success {
        background-color: $grey_bg;
        min-height: calc(100vh);
        display: flex;
        align-items: center;
    }
    .cards {
        margin: 0px auto;
        width: calc(90vw);
        max-width: 580px;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cards__card {
        @include elevationLevel('card');
        width: calc(100% - 32px);
        max-width: 520px;
        border-radius: 24px;
        display: inline-block;
        background-color: $white;
        z-index: 102;
        position: relative;
        margin-bottom: 48px;
        text-align: center;
        @media only screen and (max-width: 600px) {
            margin-bottom: 12px;
        }
    }
    .cards__card--success {
        margin-top: 1vh;
        padding-bottom: 20px;
        max-width: calc(520px);
        width: calc(90vw);
        @media only screen and (max-width: 600px) {
            margin-top: 9vh;
            padding-bottom: 56px;
        }
    }
    .cards__card--large-message {
        margin-top: 8vh;
        margin-bottom: 12px;
    }
    .succes_card__overflow-container {
        width: 100%;
        height: 100%;
        max-width: calc(520px);
        overflow: hidden;
        position: relative;
        padding-bottom: 52px;
        @media only screen and (max-width: 600px) {
            padding-bottom: 12px;
        }
    }
    .success_card__emoji {
        @include elevationLevel(1);
        width: 120px;
        height: 120px;
        margin: -60px auto 0 auto;
        border-radius: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 48px;
        transition: all 0.2s;
        background-color: $white;
        text-align: center;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            cursor: pointer;
        }
        &:active,
        &.success_card__emoji--just-shot-confetti {
            transform: scale(0.87);
        }
    }
    .first_down_indicator {
        @include elevationLevel(3);
        border-radius: 50%;
        position: relative;
        z-index: 101;
        animation: swell 4500ms cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
        &:hover {
            cursor: pointer;
        }
    }
    @keyframes swell {
        0%, 100% {
            transform: translate3d(0, 30px, 0);
        }
        50% {
            transform: translate3d(0,0px,0);
        }
    }
    #confettiCanvas {
        position: absolute;
        width: 100vw;
        height: 100vh;
        top: 0;
        z-index: 1;
        pointer-events: none;
    }
</style>
