var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"end_screen"},[_c('div',{staticClass:"success"},[_c('div',{staticClass:"cards"},[_c('div',{staticClass:"cards__card cards__card--success",class:{
                    'cards__card--large-message': _vm.hasLargeMessage,
                }},[_c('div',{staticClass:"success_card__emoji",class:{
                        'success_card__emoji--just-shot-confetti': _vm.justShotConfetti,
                    },on:{"click":_vm.shootConfetti}},[_vm._v(_vm._s(_vm.shop.success_state_emoji))]),_c('div',{staticClass:"succes_card__overflow-container"},[_vm._t("default")],2)]),_c('DownIndicator',{staticClass:"first_down_indicator",attrs:{"dark-mark":true,"color-stops":[
                    { color: '#FFF', position: '0%' },
                    { color: '#FFF', position: '50%' },
                    { color: '#FFF', position: '100%' } ]},nativeOn:{"click":function($event){return _vm.introScroll.apply(null, arguments)}}})],1),_c('canvas',{ref:"confettiCanvas",attrs:{"id":"confettiCanvas"}})]),_c('EwfPromo',{ref:"EwfPromo"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }